.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .embed-responsive {
  position: relative;
  width: 100%;
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.webcam-container,
.canvas-container {
  width: 100%;
  max-width: 640px;
}

@media (max-width: 640px) {
  .webcam-container,
  .canvas-container {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
} */
/* 웹캠 스타일 */
.embed-responsive {
  position: relative;
  width: 100%;
  height: 480px; /* 웹캠의 높이를 480px로 설정 */
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 50%; /* 상단을 50% 위치로 설정 */
  left: 50%; /* 왼쪽을 50% 위치로 설정 */
  transform: translate(-50%, -50%); /* 중앙 정렬 */
  width: 100%;
  height: 100%;
}

/* 웹캠 비율 조절 */
.embed-responsive::before {
  content: "";
  display: block;
  padding-top: 75%; /* 4:3 비율 */
}

.webcam-container {
  width: 100%;
  max-width: 640px;
  max-height: 480px; /* 최대 높이 설정 */
  margin: auto; /* 중앙 정렬을 위한 마진 설정 */
  position: relative; /* 상대 위치 설정 */
}

@media (max-width: 640px) {
  .webcam-container {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
}

/* 캔버스 스타일 */
.canvas-container {
  position: absolute;
  top: 0; /* 웹캠과 동일한 위치로 설정 */
  left: 0; /* 웹캠과 동일한 위치로 설정 */
  width: 100%;
  height: 100%;
  z-index: 10; /* 웹캠 위에 캔버스 위치 */
}
